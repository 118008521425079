import { Flex, Heading, Text } from '@chakra-ui/react';
import { Link } from 'gatsby';
import React from 'react';
import { Hero } from '../components/Hero';
import Layout from '../components/Layout';
import { useTranslation } from '../contexts/LanguageContext';

export default function NotFoundPage() {
  const { locale } = useTranslation();

  return (
    <Layout title="404: Not found" hideHero hideTitle hideFooter>
      <Hero bg="/earth.jpg">
        <Flex
          alignItems="center"
          flexDir="column"
          h="100vw"
          justifyContent="center"
        >
          <Heading as="h1" fontSize={['15vw', '10vw']} fontWeight="black">
            Ooops!
          </Heading>
          {locale === 'en' ? (
            <Text fontSize="2xl" textAlign="center">
              Ouch! Something went wrong. Try going back to the
              <Link to="/"> home page</Link>.
            </Text>
          ) : (
            <Text fontSize="2xl" textAlign="center">
              Vish! Algo deu errado. Tente voltar para a
              <Link to="/"> página inicial</Link>.
            </Text>
          )}
        </Flex>
      </Hero>
    </Layout>
  );
}
